import BookItem from "./BookItem";
import React from "react";
import useFetchData from "../../hooks/useFetchData";
const Books = () => {
  const { data } = useFetchData();
  return (
    <section id="books" className="">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 lg:gap-x-4 md:gap-6 gap-4 lg:mt-12 md:mt-9 mt-6">
        {data?.books?.map((item) => (
          <BookItem books={item} key={item._id} {...item} />
        ))}
      </div>
    </section>
  );
};

export default Books;
