import React, { useEffect, useState } from "react";
import BlogItem from "./BlogItem";
import Skeleton from "./Skeleton";

const Blogs = () => {
  const [page, setPage] = useState(0);
  const [displayBlogs, setDisplayBlogs] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  console.log(displayBlogs);
  const size = 8;
  useEffect(() => {
    fetch(
      `https://main-server.malihatabassum.com/blogs?page=${page}&&size=${size}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setDisplayBlogs(data.blogs);
        const count = data.count;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
      });
  }, [page]);
  const [loading, setLoading] = useState(false);
  //loading
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <section id="pagination" className="2xl:container mx-auto   ">
      <div className="flex items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 lg:gap-x-8 md:gap-6 gap-4  md:mt-9">
          {displayBlogs?.map((item) =>
            loading ? <Skeleton /> : <BlogItem key={item._id} {...item} />
          )}
        </div>
      </div>

      <a href="#pagination">
        <div className="pagination flex justify-center items-center ">
          {[...Array(pageCount).keys()].map((number) => (
            <button
              className={
                number === page
                  ? "selected bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500"
                  : ""
              }
              key={number}
              onClick={() => setPage(number)}
            >
              {number + 1}
            </button>
          ))}
        </div>
      </a>
    </section>
  );
};

export default Blogs;
