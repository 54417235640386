import React, { Suspense, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
import useCart from "../hooks/useCart";
import { addToDb, getStoredCart } from "../utilities/fakedb";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ImageGrid from "../components/DisplayProducts/ImageGrid";
import axios from "axios";
import { AiFillFastBackward } from "react-icons/ai";
import ReactGA from "react-ga";
const Books = React.lazy(() => import("../components/DisplayProducts/Books"));

const BookDetails = (props) => {
  const [book, setProducts] = useState({});
  const { id } = useParams();
  const [books, setBooks] = useState();
  useEffect(() => {
    const baseURL = "https://main-server.malihatabassum.com/books";
    axios.get(baseURL).then((response) => {
      setBooks(response.data.books);
    });
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    fetch(`https://main-server.malihatabassum.com/books/${id}`)
      .then((res) => res.json())
      .then((data) => setProducts(data));
  }, [id]);

  useEffect(() => {
    if (books) {
      const savedCart = getStoredCart();
      const stredCart = [];
      for (const key in savedCart) {
        const addedBook = books.find((book) => book.key === key);

        if (addedBook) {
          const quantity = savedCart[key];
          addedBook.quantity = quantity;
          stredCart.push(addedBook);
        }
      }
      setCart(stredCart);
    }
  }, [books]);
  const [cart, setCart] = useCart();
  //handeltoCart
  const handleAddToCart = (book) => {
    const exists = cart.find((pd) => pd.key === book.key);
    let newCart = [];
    if (exists) {
      const rest = cart.filter((pd) => pd.key !== book.key);
      exists.quantity = exists.quantity + 1;
      newCart = [...rest, book];
    } else {
      books.quantity = 1;
      newCart = [...cart, book];
    }

    setCart(newCart);
    // swal("Wow!!!", "Your order has added to the cart", "success")
    toast.success("Added to the cart!");
    // save to local storage (for now)
    addToDb(book.key);
  };

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const history = useHistory();

  ReactGA.event({
    category: book.title,
    action: "test action",
    label: "test-label",
    value: book.price,
  });

  return (
    <main className="xl:container xl:mx-auto px-4 h-screen ">
      <Toaster position="top-center" reverseOrder={true} />
      <div>
        <div className="flex justify-start items-center gap-2 py-12">
          <AiFillFastBackward />
          <button
            className="font-bold border-b text-gray-600"
            onClick={history.goBack}
          >
            Back
          </button>
        </div>

        <div className="flex lg:flex-row md:flex-row flex-col items-center justify-center py-8 ">
          <img
            src={book.img}
            alt="books"
            className="h-80 md:pr-8 transition transform duration-700 hover:scale-125 "
          />

          <Modal open={open} onClose={onCloseModal} center>
            <div className="p-5">
              <div className="uppercase tracking-wide text-sm text-lime-600 ">
                {book.date}
              </div>
              <p
                href="#"
                className="block mt-1 text-lg leading-tight font-semibold text-gray-900 hover:underline"
              >
                {book.title}
              </p>

              <hr className="my-2" />

              <p className="mt-2 text-gray-600">
                {book?.excerpt?.split(/\r\n|\n|\r/gm).map((line) => {
                  return (
                    <React.Fragment>
                      {line}
                      <br />
                    </React.Fragment>
                  );
                })}
              </p>

              <p className="mt-2 text-gray-600">{book.excerpt}</p>
            </div>
          </Modal>

          <div className="lg:w-1/2 lg:pl-12 lg:pr-24 ">
            <p className="md:text-3xl lg:text-4xl text-2xl font-semibold lg:leading-9 text-gray-800 ">
              {book?.title}
            </p>
            <p className="text-md leading-none text-gray-600 pb-2 fas fa-date py-4 lg:pb-6 md:pb-4 pb-2">
              {book?.date}
            </p>
            <p className="racking-wider text-base leading-5 text-gray-600 md:pb-10 pb-8">
              {book.summery?.split(/\r\n|\n|\r/gm).map((line) => {
                return (
                  <React.Fragment>
                    {line}
                    <br />
                  </React.Fragment>
                );
              })}
            </p>

            <div>
              <h1 className="text-2xl font-medium mb-4">
                Price {book.price} Tk
              </h1>
            </div>
            <div className="md:block flex items-center justify-center pb-10">
              <button
                onClick={onOpenModal}
                className="lg:w-auto  border border-gray-800 hover:text-gray-50 hover:bg-gray-800 focus:outline-none lg:px-10 px-5 lg:py-4 py-3 text-sm leading-none "
                type="button"
                data-modal-toggle="large-modal"
              >
                Read More
              </button>

              <button
                onClick={() => {
                  handleAddToCart(book);
                }}
                className="lg:w-auto  border border-gray-800 hover:text-gray-50 bg-gray-800 focus:outline-none lg:px-10 px-5 lg:py-4 py-3 text-sm leading-none text-white"
              >
                {" "}
                Add to cart
              </button>
            </div>
          </div>
        </div>
        {book.title ? (
          <div className="py-10 flex justify-center text-center">
            <Suspense
              fallback={
                <div>
                  <ImageGrid />
                </div>
              }
            >
              <div>
                <h2 className="font-semibold lg:text-4xl text-3xl lg:leading-9 md:leading-7 leading-9 text-gray-800 md:w-full w-9/12 mx-auto">
                  More Books
                </h2>
                <Books></Books>
              </div>
            </Suspense>
          </div>
        ) : (
          ""
        )}
      </div>
    </main>
  );
};

export default BookDetails;
