import React from "react";
import { BsCart2 } from "react-icons/bs";
const CartEx = (props) => {
  const { cart } = props;
  let totalQuantity = 0;

  console.log();
  let total = 0;

  for (const book of cart) {
    if (!book.quantity) {
      book.quantity = 1;
    }
    total = total + book.price * book.quantity;
    totalQuantity = totalQuantity + book.quantity;
  }

  return (
    <div>
      {totalQuantity > 0 ? (
        <div>
          <div className="relative flex cursor-pointer ">
            <span className="bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 w-6 h-6 rounded-full flex items-center justify-center text-white poppins absolute -right-2 -top-2">
              {totalQuantity}
            </span>
            <BsCart2 className="cursor-pointer w-6 h-6 text-gray-700" />
          </div>
          {props.children}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CartEx;
