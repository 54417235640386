import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBsWwO1ilK6LDwteKY-ODabLgxWlqv4dPA",
  authDomain: "maliha-tabassum.firebaseapp.com",
  projectId: "maliha-tabassum",
  storageBucket: "maliha-tabassum.appspot.com",
  messagingSenderId: "238658752375",
  appId: "1:238658752375:web:bc2646fa053165db961b72",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
