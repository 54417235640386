import { useState, useEffect } from "react";
import { getStoredCart } from "../utilities/fakedb";

const useCart = () => {
  const [cart, setCart] = useState([]);
  useEffect(() => {
    const savedCart = getStoredCart();
    const keys = Object.keys(savedCart);
    fetch("https://main-server.malihatabassum.com/books/byKeys", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(keys),
    })
      .then((res) => res.json())
      .then((books) => {
        if (books.length) {
          const storedCart = [];
          for (const key in savedCart) {
            const addedBook = books.find((book) => book.key === key);
            if (addedBook) {
              // set quantity
              const quantity = savedCart[key];
              addedBook.quantity = quantity;
              storedCart.push(addedBook);
            }
          }
          setCart(storedCart);
        }
      });
  }, []);

  return [cart, setCart];
};

export default useCart;
