import React from "react";

const ReviewItem = (props) => {
  const { title, price, quantity, key, img } = props.book;
  const { handleRemove } = props;
  return (
    <ul className="-my-6  divide-gray-200 ">
      <li key={key} className="flex py-6 ">
        <div className="h-24 w-16 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
          <img
            src={img}
            alt={img}
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div className="ml-4 flex flex-1 flex-col pr-4">
          <div>
            <div className="flex justify-between text-base font-medium text-gray-900">
              <h3>{title}</h3>
              <p className="ml-4">৳ {price}</p>
            </div>
            {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500">Quantity {quantity}</p>

            <div className="flex">
              <button
                onClick={() => handleRemove(key)}
                type="button"
                className="font-medium text-cyan-600 hover:text-cyan-500"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default ReviewItem;
