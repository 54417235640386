import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { FaThumbsUp } from "react-icons/fa";

import { Link } from "react-router-dom";

const BlogItem = (props) => {
  const excerpt = props?.excerpt;
  const title = props?.title;
  const image = props?.image;
  const date = props?.date;
  const key = props?._id;

  const handleClick = (id) => {
    console.log("clicked");
    const view = props?.view ? props?.view + 1 : 1;
    const action = "view";
    const updateFile = { view, action };

    fetch(`https://main-server.malihatabassum.com/blogs/${key}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(updateFile),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
        }
        // history.push("/dashboard");
      });
  };

  const [liked, setLiked] = useState(false);
  //set like button and count
  const handleSubmit = () => {
    setLiked(true);
    const like = props.like ? props.like + 1 : 1;
    const action = "like";
    const updateFile = { like, action };

    fetch(`https://main-server.malihatabassum.com/blogs/${key}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(updateFile),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
        }
        // history.push("/dashboard");
      });
  };
console.log(date)
  return (
    <div className="cursor-pointer">
      <div className="bg-gray-100/50 border p-3  pointer border-purple-200 ">
        <div className="flex lg:flex-row flex-col">
          <div className="">
            <img
              className="lg:w-72 object-cover lg:pr-2 lg:h-full"
              src={image}
              alt=""
            />
          </div>
          <div className="lg:pt-0 md:pt-0 pt-3">
            <div className="flex justify-between items-center">
              <span>{date?.slice(4, 15)}</span>
              
              <div className="uppercase tracking-wide text-sm text-lime-600 font-bold">
                {/* <div className="flex gap-2 justify-end">
                  <div className="border  rounded  flex items-center justify-center gap-1 px-2 h-5 w-14">
                    <AiOutlineEye className="text-blue-500" />
                    <p className="text-blue-500">{props.view}</p>
                  </div>
                  <div className="border rounded  flex items-center justify-center gap-1 px-2 h-5 w-14">
                    <FaThumbsUp className="text-blue-500" />
                    <p className="text-blue-500">{props.like}</p>
                  </div>

                  <div
                    onClick={handleSubmit}
                    className={
                      liked
                        ? "bg-blue-500 rounded flex gap-1  items-center justify-center text-gray-100 cursor-pointer capitalize h-5 px-2"
                        : " rounded flex gap-1  items-center justify-center text-blue-500 border cursor-pointer  capitalize h-5 px-2 "
                    }
                  >
                    <div className="text-sm">
                      {liked ? (
                        <span>
                          {" "}
                          <FaThumbsUp />{" "}
                        </span>
                      ) : (
                        <FaThumbsUp />
                      )}
                    </div>
                    <p className={liked && "hidden"}>{props.like || 0}</p>
                  </div>
                </div> */}
              </div>
            </div>
            <p className="block mt-1 text-lg leading-tight font-semibold text-gray-900 hover:underline bangla-font ">
              {title}
            </p>
            <Link to={`/blogs/${key}`} onClick={() => window.scrollTo(0, 0)}>
              <p
                className="mt-2 text-gray-600 block  bangla-font"
                onClick={() => handleClick(key)}
              >
                {excerpt?.slice(0, 150)}...
                <button className="text-blue-600 font-semibold pointer">
                  Read More
                </button>
              </p>
            </Link>
            {/* <Link to={`/blogs/${key}`} onClick={() => window.scrollTo(0, 0)}>
              <p
                className="mt-2 text-gray-600 hidden md:block lg:hidden "
                onClick={() => handleClick(key)}
              >
                {excerpt?.slice(0, 15)}....
                <button className="text-blue-600 font-semibold pointer">
                  Read More
                </button>
              </p>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;

// {
/* <div className="w-full  lg:flex">
            <div className=' lg:h-auto lg:w-72 md:w-full flex-none  rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden'>
                <img className='h-auto' src={`data:image/*;base64,${image}`} alt="" />
            </div>
            <div className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                <div className="mb-8">
                    <p className="text-sm text-gray-600 flex items-center fa fa-calendar">
                        <span className='pl-2'> {date.slice(4, 15)}</span>
                    </p>
                    <div className="text-gray-900 font-bold text-xl mb-2">{title}</div>

                    // {/* replace new line with br */
// <div className="tracking-wider text-base" style={{ whiteSpace: "pre-wrap" }}>
//     {excerpt && (
//         <>
//             <ReactReadMoreReadLess
//                 charLimit={200}
//                 readMoreText={"Read more ▼"}
//                 readLessText={"Read less ▲"}
//             >
//                 {
//                     excerpt
//                 }
//             </ReactReadMoreReadLess>

//         </>
//     )}
// </div>

//     </div>

// </div>

// </div > * /
// }
