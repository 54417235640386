import React from "react";
import in1 from "../../assets/Insta/in1.jpg";
import in2 from "../../assets/Insta/in2.jpg";
import in3 from "../../assets/Insta/in3.jpg";
import in4 from "../../assets/Insta/in4.jpg";
const Insta = () => {
  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 md:py-12 md:px-6 py-9 px-4">
      <div className=" text-center">
        <h2 className=" font-semibold lg:text-4xl text-3xl lg:leading-9 md:leading-7 leading-9 text-gray-800 md:w-full w-9/12 mx-auto">
          Follow Me on Instagram
        </h2>
      </div>
      <div>
        <div
          class="embedsocial-hashtag"
          data-ref="26f4c9831828044de93225d3d148ddef0f275963"
        >
          {" "}
          <a
            class="feed-powered-by-es feed-powered-by-es-feed-new"
            href="https://embedsocial.com/social-media-aggregator/"
            target="_blank"
            title="Widget by EmbedSocial"
          >
            {" "}
          </a>{" "}
        </div>
      </div>
    </div>
  );
};

export default Insta;
