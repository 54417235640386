import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import AuthProvider from "./contexts/AuthProvider";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import ErrorScreen from "./Display/ErrorScreen";
import HomeScreen from "./Display/HomeScreen";
import SignIn from "./Display/SignIn";
import Register from "./Display/Register";
import MyOrder from "./Dashboard/LocalUser/MyOrder";
import Books from "./components/DisplayProducts/Books";
import AboutUs from "./components/About/AboutUs";
import Award from "./components/Award/Award";
import Blogs from "./components/DisplayProducts/Blogs";
import Contact from "./components/Contact/Contact";
import BookDetails from "./Display/BookDetails";
import Videos from "./components/Video/Videos";
import CheckOut from "./components/CheckOut/CheckOut";
import ManageProdcuts from "./Dashboard/Admin/ManageProdcuts";
import AdminRoute from "./routes/AdminRoute";
import ImageGrid from "./components/DisplayProducts/ImageGrid";
import BlogDetails from "./Display/BlogDetails";

import TabsRender from "./components/DisplayProducts/Tabs";

import RouteWithTitle from "./hooks/RouteWithTitle";
import { hydrate } from "react-dom";
import { render } from "@testing-library/react";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga";
const Admin = React.lazy(() => import("./Dashboard/Admin/Admin"));
const App = () => {
  const helmetContext = {};
  const TRACKING_ID = "G-LDGJYSGX41";
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [changeHeader, setChangeHeader] = useState(false);
  const onChangeHeader = () => {
    if (window.scrollY >= 10) {
      setChangeHeader(true);
    } else {
      setChangeHeader(false);
    }
  };
  window.addEventListener("scroll", onChangeHeader);
  return (
    <>
      {/* <ScrollToTop showUnder={160}> */}
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <AuthProvider>
            <div className="lg:mb-10 md:mb-16 mb-10">
              <Navbar />
            </div>
            {changeHeader && (
              <button
                onClick={() => window.scrollTo(0, 0)}
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                class="inline-block p-3 bg-cyan-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-lime-700 hover:shadow-lg focus:bg-lime-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-lime-800 active:shadow-lg transition duration-150 ease-in-out  bottom-5 right-5 fixed"
                id="btn-back-to-top"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  class="w-4 h-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
                  ></path>
                </svg>
              </button>
            )}
            <Switch>
              <Route exact path="/">
                <HomeScreen />
              </Route>
              <Route exact path="/about">
                <Suspense
                  fallback={
                    <div>
                      <ImageGrid></ImageGrid>
                    </div>
                  }
                >
                  <AboutUs />
                </Suspense>
              </Route>
              <PublicRoute exact path="/signin">
                <SignIn />
              </PublicRoute>
              <Route exact path="/award">
                <Award />
              </Route>
              <Route exact path="/video">
                <Videos />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/books">
                <Books />
              </Route>
              <Route exact path="/tabs">
                <TabsRender></TabsRender>
              </Route>
              <Route exact path="/blogs">
                <Blogs />
              </Route>
              <PublicRoute exact path="/signup">
                <Register />
              </PublicRoute>
              <Route exact path="/books/:id">
                <BookDetails />
              </Route>
              <RouteWithTitle
                title="Home"
                exact
                path="/blogs/:id"
                component={BlogDetails}
              />
              {/* <Route exact path="/blogs/:id">
              <BlogDetails />
            </Route> */}
              <Route path="/shipping">
                <CheckOut></CheckOut>
              </Route>
              <AdminRoute exact path="/dashboard">
                <Suspense fallback={<div>Loading...</div>}>
                  <Admin />
                </Suspense>
              </AdminRoute>
              <PrivateRoute exact path="/dashboard/manage-all-order/:id">
                <ManageProdcuts />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/myOrder/:id">
                <MyOrder />
              </PrivateRoute>
              <Route exact path="*">
                <ErrorScreen />
              </Route>
            </Switch>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
};
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(App, rootElement);
} else {
  render(App, rootElement);
}

export default App;
