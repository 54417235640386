import React from "react";
const AwardItem = ({ title, date, youtube_link }) => {
  return (
    <div className="bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500">
      <iframe
        className="w-full h-48"
        src={youtube_link}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="true"
      ></iframe>

      <div className="py-2 px-4 w-full flex justify-between ">
        <p className="text-sm text-white font-semibold tracking-wide fas fa-date  ">
          {" "}
        </p>
      </div>
      <div className="bg-purple-100  px-3  lg:px-6 py-4l">
        <h1 className="text-lg text-gray-500 font-semibold tracking-wider">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default AwardItem;
