import React from "react";
import PropTypes from "prop-types";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
// const metaDecorator = require("../assets/metaDecorator.json");
// console.log(metaDecorator + window.location.pathname + window.location.search);
const MetaDecorator = ({ title, description, imageUrl, imageAlt, post_id }) => (
  <Helmet prioritizeSeoTags>
    <title className="bangla-font">{title}</title>
    {/* <meta name="title" content={title} />
    <meta name="description" content={description} /> */}
    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta
      property="og:url"
      content={`https://malihatabassum.com/blogs/${post_id}`}
    />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={imageUrl} />

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content={imageUrl} />
    <meta
      property="twitter:url"
      content={`https://malihatabassum.com/blogs/${post_id}`}
    />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content=""></meta>
  </Helmet>
);

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  post_id: PropTypes.string.isRequired,
};

export default MetaDecorator;
