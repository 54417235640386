import React, { useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { NavLink, useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Cart from "../Cart/Cart";
import { addToDb, getStoredCart, removeFromDb } from "../../utilities/fakedb";
import useCart from "../../hooks/useCart";
import useBooks from "../../hooks/useBooks";
import CartEx from "../Cart/CartEx";
import ReviewItem from "../ReviewItem/ReviewItem";
import axios from "axios";
const Navbar = () => {
  const history = useHistory();
  const { user, signOutUser } = useAuth();
  const [show, setshow] = useState(false);
  const [books, setBooks] = useState();
  useEffect(() => {
    const baseURL = "https://main-server.malihatabassum.com/books";
    axios.get(baseURL).then((response) => {
      setBooks(response.data.books);
    });
  }, []);

  useEffect(() => {
    if (books?.length) {
      const savedCart = getStoredCart();
      const stredCart = [];
      for (const key in savedCart) {
        const addedBook = books.find((book) => book.key === key);

        if (addedBook) {
          const quantity = savedCart[key];
          addedBook.quantity = quantity;
          stredCart.push(addedBook);
        }
      }
      setCart(stredCart);
    }
  }, [books]);
  const [cart, setCart] = useCart();
  //handeltoCart
  const handleAddToCart = (book) => {
    const exists = cart.find((pd) => pd.key === book.key);
    let newCart = [];
    if (exists) {
      const rest = cart.filter((pd) => pd.key !== book.key);
      exists.quantity = exists.quantity + 1;
      newCart = [...rest, book];
    } else {
      books.quantity = 1;
      newCart = [...cart, book];
    }

    setCart(newCart);
    // save to local storage (for now)
    addToDb(book.key);
  };
  const [show2, setShow2] = useState(false);

  const handleRemove = (key) => {
    const newCart = cart.filter((book) => book.key !== key);
    setCart(newCart);
    removeFromDb(key);
  };

  const handleProceedToShipping = () => {
    setCart([]);
    // clearTheCart();
    history.push("/shipping");
  };
  return (
    <header
      id="details"
      className="bg-purple-50 z-50 top-0 left-0 w-full shadow-md transition duration-500 fixed"
    >
      <nav className="2xl:container mx-auto py-4 px-4  md:px-6  px-4 ">
        {/* For large and Medium-sized Screen */}
        <div className="flex justify-between ">
          <div className="hidden sm:flex flex-row items-center space-x-6">
            <a
              href="https://www.facebook.com/maliha.smita"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
                  stroke="#1F2937"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>

            <a
              href="https://www.instagram.com/maliha_tabassum24/"
              target="_blank"
              alt=""
              rel="noreferrer"
            >
              <svg
                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
                  stroke="#1F2937"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.0002 11.3701C16.1236 12.2023 15.9815 13.0523 15.594 13.7991C15.2065 14.5459 14.5933 15.1515 13.8418 15.5297C13.0903 15.908 12.2386 16.0397 11.408 15.906C10.5773 15.7723 9.80996 15.3801 9.21503 14.7852C8.62011 14.1903 8.22793 13.4229 8.09426 12.5923C7.9606 11.7616 8.09226 10.91 8.47052 10.1584C8.84878 9.40691 9.45438 8.7938 10.2012 8.4063C10.948 8.0188 11.7979 7.87665 12.6302 8.00006C13.4791 8.12594 14.265 8.52152 14.8719 9.12836C15.4787 9.73521 15.8743 10.5211 16.0002 11.3701Z"
                  stroke="#1F2937"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.5 6.5H17.51"
                  stroke="#1F2937"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
            <a
              href="https://youtube.com/channel/UCCLSgXkzIkpRIO0FiPoI2ww"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
                src="https://img.icons8.com/external-tal-revivo-light-tal-revivo/24/000000/external-youtube-offers-videos-and-music-and-original-content-logo-light-tal-revivo.png"
                alt="f"
              />
            </a>
            <a
              href="https://twitter.com/MalihaT81502430?s=9"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className=" focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23 3.00005C22.0424 3.67552 20.9821 4.19216 19.86 4.53005C19.2577 3.83756 18.4573 3.34674 17.567 3.12397C16.6767 2.90121 15.7395 2.95724 14.8821 3.2845C14.0247 3.61176 13.2884 4.19445 12.773 4.95376C12.2575 5.71308 11.9877 6.61238 12 7.53005V8.53005C10.2426 8.57561 8.50127 8.18586 6.93101 7.39549C5.36074 6.60513 4.01032 5.43868 3 4.00005C3 4.00005 -1 13 8 17C5.94053 18.398 3.48716 19.099 1 19C10 24 21 19 21 7.50005C20.9991 7.2215 20.9723 6.94364 20.92 6.67005C21.9406 5.66354 22.6608 4.39276 23 3.00005V3.00005Z"
                  stroke="#1F2937"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
            </a>
          </div>
          <div className=" flex space-x-3 items-center">
            <NavLink to="/">
              <img
                src="https://res.cloudinary.com/dhl7vd8co/image/upload/v1674827558/yuxidbox9jedp1eefqwl.png"
                alt=""
                width={34}
                height={34}
              />
            </NavLink>

            <NavLink to="/">
              {" "}
              <h1 className="text-xl font-normal text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500">
                Dr. Maliha Tabassum
              </h1>
            </NavLink>

            <div
              className="md:hidden lg:hidden"
              onClick={() => setShow2(!show2)}
            >
              <CartEx cart={cart}></CartEx>
            </div>
          </div>

          {/* cart for mobile */}
          <div className="relative flex-row space-x-3 md:hidden lg:hidden ">
            {show2 && (
              <ul className="visible transition duration-300  shadow rounded mt-2 pb-1  absolute  -right-16  mt-10  w-80">
                <div className="pointer-events-auto">
                  <div className="flex h-full flex-col bg-purple-100shadow-xl  rounded-md">
                    <div className="flex-1 py-6 px-4 sm:px-6 bg-purple-200 ">
                      <div className="flex items-start justify-between ">
                        <h1 className="text-lg font-medium text-gray-900">
                          {" "}
                          Shopping cart{" "}
                        </h1>
                        <div
                          onClick={() => setShow2(!show2)}
                          className="ml-3 flex h-7 items-center"
                        >
                          <button>
                            <p className="text-xl font-semibold">X</p>
                          </button>
                        </div>
                      </div>
                      <div className="pt-5 pt-5 overflow-y-auto h-64">
                        {/* review item */}
                        <div className="">
                          {cart.map((book) => (
                            <ReviewItem
                              key={book.key}
                              book={book}
                              handleRemove={handleRemove}
                            ></ReviewItem>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => setShow2(!show2)}
                      className="mt-6 flex justify-center text-center text-sm text-gray-500"
                    >
                      {/* cart section */}
                      <Cart cart={cart}>
                        <p
                          onClick={handleProceedToShipping}
                          className="flex items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 px-8 py-3 text-base font-medium text-white shadow-sm hover:bg-cyan-700 cursor-pointer "
                        >
                          Checkout
                        </p>
                      </Cart>
                    </div>

                    <div className="mt-6 flex justify-center text-center text-sm text-gray-500"></div>
                  </div>
                </div>
              </ul>
            )}
          </div>

          {/* cart for desktop */}

          <div className="hidden sm:flex flex-row space-x-3 ">
            <div className="absolate hidden lg:block md:block ">
              <div className="flex-row space-x-3">
                {show2 && (
                  <ul className="visible transition duration-300  shadow rounded mt-2  right-10   absolute mt-10 ">
                    <div className="pointer-events-auto lg:w-screen max-w-md w-96">
                      <div className="flex h-full flex-col bg-purple-100shadow-2xl rounded-md">
                        <div className="flex-1  py-6 px-4 sm:px-6 bg-purple-200   ">
                          <div className="flex items-start justify-between">
                            <h1 className="text-lg font-medium text-gray-900">
                              {" "}
                              Shopping cart{" "}
                            </h1>
                            <div
                              onClick={() => setShow2(!show2)}
                              className="ml-3 flex h-7 items-center"
                            >
                              <button>
                                <p className="text-xl font-semibold">X</p>
                              </button>
                            </div>
                          </div>

                          <div className="pt-5 overflow-y-auto h-64">
                            {/* review item */}
                            {cart.map((book) => (
                              <ReviewItem
                                key={book.key}
                                book={book}
                                handleRemove={handleRemove}
                              ></ReviewItem>
                            ))}
                          </div>
                        </div>
                        <div
                          onClick={() => setShow2(!show2)}
                          className="py-6 flex justify-center text-center text-sm text-gray-500 cursor-pointer"
                        >
                          {/* cart section */}
                          <Cart cart={cart}>
                            <p
                              onClick={handleProceedToShipping}
                              className="flex items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 px-8 py-3 text-base font-medium text-white shadow-sm hover:bg-cyan-700 cursor-pointer"
                            >
                              Checkout
                            </p>
                          </Cart>
                        </div>
                      </div>
                    </div>
                  </ul>
                )}
              </div>
            </div>

            <div
              className="rounded-md flex space-x-1  justify-center items-center "
              onClick={() => setShow2(!show2)}
            >
              <CartEx cart={cart}></CartEx>
            </div>

            <NavLink
              to="/"
              className="rounded-md flex space-x-1 w-14 h-10 font-normal text-sm leading-3 text-cyan-700 bg-purple-100 focus:outline-none focus:bg-purple-200  hover:bg-purple-200  duration-150 justify-center items-center "
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              onClick={() => window.scrollTo(0, 0)}
              className="rounded-md flex space-x-1 w-14 h-10 font-normal text-sm leading-3 text-cyan-700 bg-purple-100 focus:outline-none focus:bg-purple-200  hover:bg-purple-200  duration-150 justify-center items-center"
            >
              About
            </NavLink>

            <NavLink
              smooth
              to={"/contact#contacts"}
              className="rounded-md flex space-x-1 w-14 h-10 font-normal text-sm leading-3 text-cyan-700 bg-purple-100 focus:outline-none focus:bg-purple-200  hover:bg-purple-200  duration-150 justify-center items-center"
            >
              Contact
            </NavLink>

            {user.displayName ? (
              <>
                <NavLink
                  to="/dashboard"
                  onClick={() => setshow(!show)}
                  className="rounded-md flex space-x-1  font-normal text-sm leading-3 text-cyan-700 bg-purple-100 focus:outline-none focus:bg-purple-200  hover:bg-purple-200  duration-150 justify-center items-center px-2"
                >
                  Dashboard
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  to="/signin"
                  onClick={() => setshow(!show)}
                  className="rounded-md flex space-x-1  duration-150 justify-center items-center"
                >
                  <button className="bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 hover:bg-gray-100 text-gray-100 font-semibold py-1 px-4 border border-gray-400 rounded shadow">
                    Login
                  </button>
                </NavLink>
              </>
            )}

            {user.displayName ? (
              <button
                onClick={signOutUser}
                className="bg-purple-100hover:bg-gray-100 text-gray-800 font-semibold py-1 px-4 border border-gray-400 rounded shadow"
              >
                Log Out
              </button>
            ) : (
              ""
            )}
          </div>
          {/* Burger Icon */}
          <div
            id="bgIcon"
            onClick={() => setshow(!show)}
            className={`focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  justify-center items-center sm:hidden cursor-pointer`}
          >
            <svg
              className={`${show ? "hidden" : ""}`}
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" transform duration-150"
                d="M4 6H20"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 12H20"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                className=" transform duration-150"
                d="M4 18H20"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg
              className={`${show ? "block" : "hidden"}`}
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        {/* Mobile and small-screen devices (toggle Menu) */}
        <div
          id="MobileNavigation"
          className={`${show ? "block" : "hidden"} sm:hidden mt-4 mx-auto`}
        >
          <div className="flex flex-row items-center justify-center space-x-6"></div>
          <div className="flex flex-col gap-4 mt-4 w-80 mx-auto ">
            <NavLink to="/" onClick={() => setshow(!show)}>
              <p className=" flex space-x-2 w-full h-7 font-normal text-sm leading-3 text-cyan-700 bg-cyan-600 bg-opacity-0 hover:opacity-100 duration-100  focus:outline-none focus:bg-purple-200  hover:bg-purple-200  duration-150 justify-center items-center border-b-2">
                Home
              </p>
            </NavLink>
            <NavLink to="/about" onClick={() => setshow(!show)}>
              <p className="flex space-x-2 w-full h-7 font-normal text-sm leading-3 text-cyan-700 bg-cyan-600 bg-opacity-0 hover:opacity-100 duration-100  focus:outline-none focus:bg-purple-200  hover:bg-purple-200  duration-150 justify-center items-center border-b-2">
                About
              </p>
            </NavLink>

            <NavLink to="/contact" onClick={() => setshow(!show)}>
              <p className=" flex space-x-2 w-full h-7 font-normal text-sm leading-3 text-cyan-700 bg-cyan-600 bg-opacity-0 hover:opacity-100 duration-100  focus:outline-none focus:bg-purple-200  hover:bg-purple-200  duration-150 justify-center items-center border-b-2">
                Contact
              </p>
            </NavLink>

            {user.displayName ? (
              <>
                <NavLink
                  to="/dashboard"
                  onClick={() => setshow(!show)}
                  className="flex space-x-2 w-full h-7 font-normal text-sm leading-3  bg-cyan-600 bg-opacity-0 hover:opacity-100 duration-100  focus:outline-none focus:bg-purple-200  hover:bg-purple-200  duration-150 justify-center items-center border-b-2"
                >
                  Dashboard
                </NavLink>

                {/* <div className='items-center'><img src={user?.photoURL} alt={user.displayName} className="w-10 h-10 rounded-full items-center" /></div> */}

                <p className="flex space-x-2 w-full h-7 font-normal text-md leading-3  bg-cyan-600 bg-opacity-0 hover:opacity-100 duration-100  focus:outline-none focus:bg-purple-200  hover:bg-purple-200  duration-150 justify-center items-center border-b-2">
                  You are logged in as {user.displayName}
                </p>
                <FiLogOut
                  className="flex space-x-2 w-full h-7 font-normal text-md leading-3  bg-cyan-600 bg-opacity-0 hover:opacity-100 duration-100  focus:outline-none focus:bg-purple-200  hover:bg-purple-200  duration-150 justify-center items-center border-b-2 "
                  onClick={signOutUser}
                />
              </>
            ) : (
              <>
                <button
                  className="flex space-x-2 h-7 font-normal text-sm leading-3 text-white  bg-opacity-0  justify-center items-center"
                  onClick={() => history.push("/signin")}
                >
                  {" "}
                  <span
                    onClick={() => setshow(!show)}
                    className="border-2 tab-text bg-purple-200  p-2 rounded hover:opacity-100 duration-100  
                                        focus:outline-none focus:bg-purple-200  duration-150"
                  >
                    Sign In
                  </span>{" "}
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
