import React, { useState } from "react";
import img2 from "../../assets/story_skill/maliha_story_2.jpg";
import img3 from "../../assets/story_skill/maliha_story_3.jpg";

const About2 = () => {
  const [readMore, setReadMore] = useState(false);
  const extraContent = (
    <div>
      <p className="extra-content text-lg md:text-md">
        Now let's be serious about myself. I ain't a gold digger! I'm a goal
        digger! "Don't judge a book by its cover"-this proverb is applicable for
        me. I am not that much superficial innerly as I am assumed to be on very
        first observation according to look! But itz really a very tough
        question to answer! Probably,a girl who has never liked typicality,ever!
        A girl who only listens to what her heart says and nothing else! A girl
        who dreams dreams and dreams and spares no stone unturned to make each
        and every dream come true! A girl who hates
        structural,monotonous,rotationary life style of a typical normal girl
        and aspires to break the rules and make her own rules of living! A girl
        who,all her life,longed for making difference! A girl with huge guts! An
        unconventional girl in But respects religion,morals,values who thinks
        Islam is not as hard as it is described by some fellows! A girl who
        loves her family most! Lets come to 50 (Who knws more or less) shades of
        grey bout meh. A lot more emotional I am. Sometimes totally damn care
        and don't give fucking care to what people backbitch bout me! My
        boggling round eyes are filled with lots of dreams. I am fulfilling my
        dreams one after another. Besides balancing my medical career in
        psychiatry,I'd like to make a drastical change in Bangladeshi book
        industry and media by visualizing my medical thriller fictions into
        celluloids. To be the first ever Oscar winning director cum writer is my
        ultimate dream.
      </p>
    </div>
  );
  const linkName = readMore ? "Read Less" : "Read More...";

  const [readMoreNew, setReadMoreNew] = useState(false);

  const extraContentNew = (
    <div>
      <p className="extra-content text-lg md:text-md">
        A girl who dreams dreams and dreams and spares no stone unturned to make
        each and every dream come true! A girl who hates
        structural,monotonous,rotationary life style of a typical normal girl
        and aspires to break the rules and make her own rules of living! A girl
        who,all her life,longed for making difference! A girl with huge guts! An
        unconventional girl in But respects religion,morals,values who thinks
        Islam is not as hard as it is described by some fellows! A girl who
        loves her family most! Lets come to 50 (Who knws more or less) shades of
        grey bout meh. A lot more emotional I am. Sometimes totally damn care
        and don't give fucking care to what people backbitch bout me! My
        boggling round eyes are filled with lots of dreams. I am fulfilling my
        dreams one after another. Besides balancing my medical career in
        psychiatry,I'd like to make a drastical change in Bangladeshi book
        industry and media by visualizing my medical thriller fictions into
        celluloids. To be the first ever Oscar winning director cum writer is my
        ultimate dream.
      </p>
    </div>
  );
  const linkNameNew = readMoreNew ? "Read Less" : "Read More...";

  return (
    <section className="text-white bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500">
      <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 mx-4 pb-10">
        <div className=" hidden lg:block xl:block md:block lg:w-8/12  md:w-9/12 w-full text-center mx-auto ">
          <h2 className=" font-semibold lg:text-4xl text-3xl lg:leading-9 md:leading-7 leading-9 text-gray-100 md:w-full w-9/12 mx-auto">
            My Story & Skills
          </h2>

          <p className="font-normal text-lg md:text-md leading-6 text-gray-200 mt-6 ">
            A high profile celebrity and topmost glamourous actress seeks help
            of doctor cum private investigstor Maliha Tabassum (In this short
            story Dr. Maliha introduced herself as a private investigator cum
            doctor).
          </p>
        </div>

        <div className="lg:mt-16 sm:mt-12 flex lg:flex-row-reverse items-center justify-evenly flex-col lg:gap-5 xl:gap-5">
          <div className="w-full xl:w-5/12 lg:w-6/12">
            <h1 className="text-2xl font-semibold leading-4 text-gray-100 pt-10 ">
              My Story
            </h1>

            <p className="font-normal text-base leading-6 text-gray-200 mt-4 text-lg md:text-md">
              Hey, This is Maliha Tabassum. If I have to introduce myself to
              others, you'd probably be between the devil and the deep sea.
              Suppose,I am a book. If you could've read me thoroughly, you'd
              probably think taking a short look on the flap and cover- I am a
              predictable romantic short story. But if you finish reading the
              book,you'd be embezzled with psychological thrilling element.
            </p>
            <div className="py-2 lg:hidden md:hidden">
              <button
                className="read-more-link py-2 font-bold"
                onClick={() => {
                  setReadMore(!readMore);
                }}
              >
                <h2 className="text-gray-400 font-medium">{linkName}</h2>
              </button>
              {readMore && extraContent}
            </div>

            <p className="font-normal text-base leading-6 text-gray-200 mt-6 hidden lg:block md:block md:pb-4 text-lg md:text-md">
              Now let's be serious about myself. I ain't a gold digger! I'm a
              goal digger! "Don't judge a book by its cover"-this proverb is
              applicable for me. I am not that much superficial innerly as I am
              assumed to be on very first observation according to look! But itz
              really a very tough question to answer! Probably,a girl who has
              never liked typicality,ever! A girl who only listens to what her
              heart says and nothing else!{" "}
            </p>
            <div className="hidden lg:block md:block">
              <button
                className="read-more-link"
                onClick={() => {
                  setReadMoreNew(!readMoreNew);
                }}
              >
                <h2 className="text-gray-400 font-medium">{linkNameNew}</h2>
              </button>
              {readMoreNew && extraContentNew}
            </div>
          </div>

          <div className="lg:flex items-center w-full lg:w-1/2 ">
            <img
              className="lg:block hidden w-full rounded"
              src={img2}
              alt="story "
            />
            <img
              className="lg:hidden sm:block hidden rounded w-full h-3/4"
              src={img2}
              alt="story "
            />
            <img
              className="sm:hidden block w-full rounded"
              src={img3}
              alt="story "
            />
          </div>
        </div>
      </div>
      {/* skil details */}
    </section>
  );
};

export default About2;
