import React from "react";
const Cart = (props) => {
  const { cart } = props;

  let totalQuantity = 0;
  let total = 0;

  for (const book of cart) {
    if (!book.quantity) {
      book.quantity = 1;
    }
    total = total + book.price * book.quantity;
    totalQuantity = totalQuantity + book.quantity;
  }
  const grandTotal = total;

  return (
    <div>
      <div className="flex flex-col space-y-4 w-full">
        <div className="flex justify-between items-center w-full pb-5">
          <p className="text-base font-semibold leading-4 text-gray-800 ">
            Subtotal
          </p>

          <p className="text-base font-semibold leading-4 text-gray-600 ">
            {" "}
            Tk. {grandTotal}
          </p>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default Cart;
