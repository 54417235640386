import React, { useEffect, useState } from "react";

import {
  AiFillFastBackward,
  AiOutlineAccountBook,
  AiOutlineCalendar,
  AiOutlineEye,
} from "react-icons/ai";

import { useHistory, useParams } from "react-router-dom";
import Skeleton2 from "../components/DisplayProducts/Skeleton2";
import axios from "axios";
import BlogSkeleton from "./BlogSkeleton";
import MetaDecorator from "../meta/MetaDecorator";
import ReactGA from "react-ga";

// import MetaDecorator from "../meta/MetaDecorator";
import { FaSpinner, FaThumbsUp } from "react-icons/fa";
import { Footer } from "rsuite";
const BlogDetails = () => {
  const [blog, setBlog] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [key, setID] = useState(id);
  const history = useHistory();

  const [liked, setLiked] = useState(false);
  //set like button and count
  const handleSubmit = () => {
    setLiked(true);
    const like = blog.like ? blog.like + 1 : 1;
    const action = "like";
    const updateFile = { like, action };

    fetch(`https://main-server.malihatabassum.com/blogs/${key}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(updateFile),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
        }
        // history.push("/dashboard");
      });
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  ReactGA.event({
    category: blog.title,
    action: "test action",
    label: "test-label",
    value: blog.id,
  });

  useEffect(() => {
    fetch(`https://main-server.malihatabassum.com/blogs/${key}`)
      .then((res) => res.json())
      .then((data) => setBlog(data));
  }, [key]);

  const handleClick = (id) => {
    setID(id);
    setLiked(false);
    console.log("clicked");
    const view = blog?.view ? blog?.view + 1 : 1;
    const action = "view";
    const updateFile = { view, action };

    fetch(`https://main-server.malihatabassum.com/blogs/${key}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(updateFile),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          console.log("viewd");
        }
        // history.push("/dashboard");
      });
  };

  //loading
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [post, setPost] = React.useState([]);
  React.useEffect(() => {
    const url = "https://main-server.malihatabassum.com/blogs";
    axios.get(url).then((response) => {
      setPost(response.data.blogs);
    });
  }, []);

  if (!post) return null;

  return (
    <>
      <MetaDecorator
        title={blog?.title}
        description={blog?.excerpt?.slice(0, 70)}
        imageUrl={blog?.image}
        post_id={blog?._id}
      ></MetaDecorator>
      <div
        className="container mx-auto px-4 h-screen overflow-visible pt-5 bangla-font"
        key={blog._id}
      >
        <div className="">
          <div class="flex gap-2 lg:flex-row md:flex-row flex-col ">
            <div class="lg:w-2/3 md:w-2/3 w-full  px-4 py-4 lg:py-8 bg-purple-50 ">
              <div className="flex justify-between items-center">
                <div
                  className="flex justify-start items-center gap-2 cursor-pointer"
                  onClick={history.goBack}
                >
                  <AiFillFastBackward />
                  <button className="font-bold border-b text-gray-600">
                    Back
                  </button>
                </div>

                {/* <div className="uppercase tracking-wide text-sm text-lime-600  flex items-center justify-start gap-2">
                  <div className="border border-blue-500 rounded  flex items-center justify-center gap-1 px-2 h-5">
                    <AiOutlineEye className="text-blue-500" />
                    <p className="text-blue-500">{blog?.view}</p>
                  </div>
                  <div
                    onClick={handleSubmit}
                    className={
                      liked
                        ? "bg-green-500 rounded flex gap-1  items-center justify-center text-gray-100 cursor-pointer capitalize h-5 px-2"
                        : "bg-blue-500 rounded flex gap-1  items-center justify-center text-gray-100 cursor-pointer  capitalize h-5 px-2 "
                    }
                  >
                    <div className="text-sm">
                      {liked ? (
                        <span>
                          {" "}
                          <FaThumbsUp />{" "}
                        </span>
                      ) : (
                        <FaThumbsUp />
                      )}
                    </div>
                    <p className={liked && "hidden"}>{blog.like || 0}</p>
                  </div>
                </div> */}
              </div>

              {loading ? (
                <BlogSkeleton />
              ) : (
                <div>
                  <p
                    href="#"
                    className="block mt-1 text-xl leading-tight font-semibold text-gray-900 hover:underline pb-2"
                  >
                    {blog?.title}
                  </p>
                  <div className="flex gap-2">
                    <AiOutlineCalendar className="text-xl" />
                    <p> {blog?.date?.slice(4, 15)}</p>
                  </div>
                  <div>
                    <img src={blog.image} alt="" />
                  </div>
                  <hr className="my-2" />
                  <p className="mt-2 text-gray-800">
                    {blog?.summary?.split(/\r\n|\n|\r/gm).map((line) => {
                      return (
                        <React.Fragment>
                          {line}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </p>
                  <p className="mt-2 text-gray-600 text-md">
                    {blog?.excerpt?.split(/\r\n|\n|\r/gm).map((line) => {
                      return (
                        <React.Fragment>
                          {line}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </p>
                </div>
              )}
            </div>

            <div class=" lg:w-1/3 md:w-1/3 w-full pb-20  px-4 pb-20 bg-purple-50 py-8">
              <h1 className="lg:text-4xl md:text-3xl text-xl font-semibold text-gray-800 text-center py-3 ">
                Read More blogs
              </h1>

              <div>
                <ol className="flex flex-col justify-start gap-2">
                  {post?.map((data) =>
                    loading ? (
                      <Skeleton2 />
                    ) : (
                      <div
                        onClick={() => window.scrollTo(0, 0)}
                        className="flex justify-between items-center  gap-2 text-left border-b"
                      >
                        <div className="flex gap-2 justify-center ">
                          <img src={data.image} alt="" className="h-24 w-32" />
                          <div>
                            <button
                              onClick={() => handleClick(data._id)}
                              className="text-blue-500  border-gray-300 font-normal  text-xl focus:text-blue-800 text-left"
                            >
                              {data.title}
                            </button>
                          </div>
                        </div>
                        {/* <div className="flex gap-2">
                          <div className="border  rounded  flex items-center justify-center gap-1 px-2 h-5 w-14">
                            <AiOutlineEye className="text-blue-500" />
                            <p className="text-blue-500">{data.view}</p>
                          </div>
                        </div> */}
                      </div>
                    )
                  )}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetails;
