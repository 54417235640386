import axios from "axios";
import React, { useEffect, useState } from "react";

import AwardItem from "./AwardItem";
const Award = () => {
  //loading

  const [awards, setAwards] = useState();
  useEffect(() => {
    const baseURL = "https://main-server.malihatabassum.com/awards";
    axios.get(baseURL).then((response) => {
      setAwards(response.data.awards);
    });
  }, []);

  return (
    <div className="mx-2">
      <section id="award">
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 lg:gap-x-8 md:gap-6 gap-4 lg:mt-12 md:mt-9 mt-6">
            {awards?.map((item) => (
              <AwardItem key={item._id} {...item} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Award;
