import React, { useEffect, useState } from "react";

import Video from "./Video";
import Skeleton from "../../components/DisplayProducts/Skeleton";
const Videos = () => {
  const [page, setPage] = useState(0);
  const [displayBlogs, setDisplayVideos] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const size = 6;
  useEffect(() => {
    fetch(
      `https://main-server.malihatabassum.com/videos?page=${page}&&size=${size}`
    )
      .then((res) => res.json())
      .then((data) => {
        setDisplayVideos(data.videos);
        const count = data.count;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
      });
  }, [page]);
  const [loading, setLoading] = useState(false);
  //loading
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className=" 2xl:container 2xl:mx-auto lg:py-16 md:py-12 md:px-6 py-9 px-4 ">
      <section id="award">
        <div className="text-center">
          <h2 className=" font-semibold lg:text-4xl text-3xl lg:leading-9 md:leading-7 leading-9 text-gray-800 md:w-full w-9/12 mx-auto">
            Featured Videos
          </h2>
        </div>
        <div>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8 mx-4 py-10">
            {displayBlogs?.map((item) =>
              loading ? (
                <Skeleton key={item._id} />
              ) : (
                <Video key={item._id} {...item} />
              )
            )}
          </div>
        </div>

        <div className="pagination flex justify-center items-center">
          {[...Array(pageCount).keys()].map((number) => (
            <button
              className={
                number === page
                  ? "selected bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500"
                  : ""
              }
              key={number}
              onClick={() => setPage(number)}
            >
              {number + 1}
            </button>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Videos;
