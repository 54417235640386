import React from "react";
import { Link } from "react-router-dom";

const BookItem = (props) => {
  const { title, img, buy_link } = props.books;
  return (
    <>
      <div className="relative mb-10 mx-2 transition transform duration-700 hover:shadow-xl hover:scale-105 md:p-4 lg:p-4 relative ">
        <div className=" relative group">
          <div className=" flex justify-center items-center opacity-0 bg-gradient-to-t from-gray-800 via-gray-800 to-opacity-30 group-hover:opacity-50 absolute top-0 left-0 h-full w-full"></div>
          <img className="w-full" src={img} alt="maliha-tabassum-book" />
          <a href="#books">
            <div className=" absolute bottom-0 p-8 w-full opacity-0 group-hover:opacity-100">
              {/* <Link to={`/books/${_id}`} onClick={() => window.scrollTo(0, 0)}>
                <button className="bg-transparent font-medium text-base leading-4 border-2 border-white py-3 w-full mt-2 text-white">
                  View Details
                </button>
              </Link> */}
              <button className="bg-transparent font-medium text-base leading-4 border-2 border-white py-3 w-full mt-2 text-white">
                <a
                target="_blank" rel="noreferrer" href={buy_link} 
                > View Details</a>
                </button>
              
            </div>
          </a>
        </div>
        <p className=" font-normal text-xl leading-5 text-gray-800 md:mt-6 mt-4 bangla-font">
          {title}
        </p>
      </div>
    </>
  );
};

export default BookItem;
