import React from "react";

const AwardItem = ({ title, category, img, position, year, given_by }) => {
  return (
    <div className=" transition transform duration-700  hover:scale-105 relative">
      <img className="w-full rounded-t-2xl " src={img} alt="awardImage" />
      <div className="py-2 px-4 w-full flex justify-between bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 ">
        <p className="text-sm text-white font-semibold tracking-wide fas fa-award  ">
          {" "}
          {position}
        </p>
        <p className="text-sm text-white font-semibold tracking-wide">{year}</p>
      </div>
      <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl h-40">
        <h1 className="text-lg text-gray-900 font-semibold tracking-wider">
          Awarded {title}
        </h1>
        <p className="text-gray-700 text-md  lg:text-base  lg:leading-8 pr-4  mt-2">
          {category} || by <span className="font-semibold"> {given_by}</span>
        </p>
      </div>
    </div>
  );
};

export default AwardItem;
