import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
function Contact() {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wxr7k0f",
        "template_zyk387i",
        form.current,
        "user_aYnu9C0L4QMAmLa2XTpSE"
      )
      .then(
        (result) => {
          swal("Message!", "Successfully Send!", "success");
        },
        (error) => {}
      );
  };

  return (
    <section id="contacts">
      <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
        <form ref={form} onSubmit={sendEmail}>
          <div className=" lg:py-8  relative">
            <div className="flex flex-col items-center xl:justify-center 2xl:justify-center xl:px-0 px-4">
              <h1 className="text-4xl md:text-5xl lg:text-7xl font-bold tracking-wider tab-text">
                Let’s Talk
              </h1>
              <div className="w-full 2xl:w-8/12 mt-3">
                <h2 className="text-gray-800 text-base md:text-lg leading-8 tracking-wider text-center">
                  For enquiries, please email me using the form below
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 lg:gap-x-8 md:gap-6 gap-4 lg:mt-12 md:mt-9 mt-2">
                  <div className="mt-4 md:mt-8">
                    <p className="text-gray-800 text-base font-medium">Name</p>
                    <input
                      className="mt-3 text-base border-2 w-11/12 lg:w-full xl:w-10/12 hover:border-cyan-600 focus:border-cyan-600 focus:outline-none border-black py-5 pl-4 text-gray-800"
                      type="text"
                      placeholder="Your Name"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div className="mt-4 md:mt-8">
                    <p className="text-gray-800 text-base font-medium">
                      Email Address
                    </p>
                    <input
                      className="mt-3 text-base border-2 w-11/12 lg:w-full xl:w-10/12 hover:border-cyan-600 focus:border-cyan-600 focus:outline-none border-black py-5 pl-4 text-gray-800"
                      type="email"
                      placeholder="example@mail.com"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                  <div className="mt-4 md:mt-8">
                    <p className="text-gray-800 text-base font-medium">
                      Message
                    </p>
                    <textarea
                      className="mt-3 text-base border-2 w-11/12 lg:w-full xl:w-10/12 resize-none hover:border-cyan-600 focus:border-cyan-600 focus:outline-none border-black xl:h-40 py-5 pl-4 text-gray-800"
                      type="text"
                      name="message"
                      placeholder="Write us something..."
                      defaultValue=""
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    />
                  </div>
                  <div className="lg:mt-10 md:mt-12 py-5">
                    <button
                      className="py-3 md:py-5 px-5 md:px-10 bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 text-white hover:opacity-90 ease-in duration-150 text-sm md:text-lg tracking-wider font-semibold"
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Contact;
