import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton2 = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={160}
      viewBox="0 0 400 160"
      backgroundColor="#d9d9d9"
      foregroundColor="#ededed"
      {...props}
    >
      <rect x="50" y="2" rx="4" ry="2" width="343" height="22" />
    </ContentLoader>
  );
};

export default Skeleton2;
