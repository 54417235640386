import React, { useEffect, useState } from "react";
import Award from "../Award/Award";
import Blogs from "./Blogs";
import Books from "./Books";
import News from "../News/News";
import ImageGrid from "./ImageGrid";

const Tabs = ({ color }) => {
  const [openTab, setOpenTab] = React.useState(1);

  return (
    <section className="">
      <div className="2xl:container 2xl:mx-auto  md:py-5 ">
        <div className="flex flex-wrap lg:w-full justify-center align-center">
          <ul
            className="flex mb-0 lg:w-3/5 md:w-3/5 w-full list-none flex-wrap  flex-row"
            role="tablist"
          >
            <li className="-mb-px  last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-2 py-3 shadow-lg block leading-normal " +
                  (openTab === 1
                    ? "bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 text-white"
                    : "text-purple-500 bg-gray-200")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#books"
                role="tablist"
              >
                <i className="fas fa-book text-base mr-1"></i>Blogs
              </a>
            </li>
            <li className="-mb-px  last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-2 py-3 shadow-lg  block leading-normal " +
                  (openTab === 2
                    ? "bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 text-white"
                    : "text-purple-500 bg-gray-200")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#blogs"
                role="tablist"
              >
                <i className="fas fa-pen text-base mr-1"></i>Books
              </a>
            </li>
            <li className="-mb-px  last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-2 py-3 shadow-lg  block leading-normal " +
                  (openTab === 3
                    ? "bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 text-white"
                    : "text-purple-500 bg-gray-200")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#certifications"
                role="tablist"
              >
                <i className="fas fa-images text-base mr-1"></i>Gallery
              </a>
            </li>
            <li className="-mb-px  last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-2 py-3 shadow-lg  block leading-normal " +
                  (openTab === 4
                    ? "bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 text-white"
                    : "text-purple-500 bg-gray-200")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#gallery"
                role="tablist"
              >
                <i className="fas fa-award text-base mr-1"></i>Awards
              </a>
            </li>
          </ul>

          <div className=" flex flex-col min-w-0 break-words  w-full rounded ">
            <div className=" flex-auto bg-sec">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="books">
                  <Blogs />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="blogs">
                  <Books />
                </div>
                <div
                  className={openTab === 3 ? "block" : "hidden"}
                  id="gallery"
                >
                  <News />
                </div>
                <div
                  className={openTab === 4 ? "block" : "hidden"}
                  id="certifications"
                >
                  <Award />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default function TabsRender() {
  return (
    <>
      <Tabs color="cyan" />
    </>
  );
}
