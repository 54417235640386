import React, { useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

const RouteWithTitle = ({
  title,
  link,
  image,
  summary,
  component: Component,
  ...rest
}) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Helmet>
            <title>{title}</title>
            <meta property="og:url" content={link} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={summary} />
            <meta property="og:image" content={image} />
            <meta property="og:image:alt" content={image} />
            <meta property="og:type" content="article" />
          </Helmet>
          <Component {...props} />
        </>
      )}
    />
  );
};

export default withRouter(RouteWithTitle);
