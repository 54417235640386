import React, { Suspense } from "react";
import Banner from "../components/Header/Banner";
import TabsRender from "../components/DisplayProducts/Tabs";
import Insta from "../components/DisplayProducts/Insta";
import About2 from "../components/About/About2";

const Videos = React.lazy(() => import("../components/Video/Videos"));
const Contact = React.lazy(() => import("../components/Contact/Contact"));
const Footer = React.lazy(() => import("../components/Footer/Footer"));
const JoinGroup = React.lazy(() =>
  import("../components/DisplayProducts/JoinGroup")
);
const MainSkills = React.lazy(() => import("../components/About/MainSkills"));
const HomeScreen = () => {
  return (
    <>
      <div className="">
        <Banner></Banner>
        <TabsRender exact path="/tabs" />
        <About2></About2>
        <Suspense fallback={<div>Loading...</div>}>
          <section>
            <MainSkills></MainSkills>
            <Videos></Videos>
            <Insta></Insta>
            <JoinGroup></JoinGroup>
            <Contact exact path="/contact" />
            <Footer />
          </section>
        </Suspense>
      </div>
    </>
  );
};

export default HomeScreen;
