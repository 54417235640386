import { FiBook } from "react-icons/fi";
import swal from "sweetalert";
import useOrder from "../../hooks/useOrder";
import Heading from "../Admin/Heading";
import useAuth from "../../hooks/useAuth";
const MyOrder = () => {
  const [orders, setOrder] = useOrder();
  const { user } = useAuth();

  const handleDelete = (id) => {
    const proceed = window.confirm("Are you sure want to Delete");
    if (proceed) {
      const url = `http://server.malihatabassum.com/orders/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0)
            swal("Deleted", "Order Delete Successfully!", "success");
          const remainingUsers = orders.filter((user) => user._id !== id);
          setOrder(remainingUsers);
        });
    }
  };

  return (
    <div>
      {/* heading   */}
      <Heading text="Manage My Order" />
      {/* blog s  */}
      <div className="flex flex-col my-8">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden sm:rounded-lg shadow-md">
              <table className="min-w-full">
                <thead className="bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 poppins">
                  <tr>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white px-6 py-3 text-left uppercase tracking-wider"
                    >
                      Order Status
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white px-6 py-3 text-left uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white px-6 py-3 text-left uppercase tracking-wider"
                    >
                      Invoice Number
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white px-6 py-3 text-left uppercase tracking-wider"
                    >
                      Book Name
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white px-6 py-3 text-left uppercase tracking-wider"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white px-6 py-3 text-left uppercase tracking-wider"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white px-6 py-3 text-left uppercase tracking-wider"
                    >
                      Payment Status
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white px-6 py-3 text-left uppercase tracking-wider"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders
                    ?.filter((item) => item.email === user.email)
                    ?.map((item) => (
                      <tr className="bg-white border-b poppins" key={item._id}>
                        <td className="px-6 py-4 whitespace-nowrap flex flex-col h-24 items-center justify-center">
                          <div className="flex items-center justify-center space-x-3">
                            {item.status === "pending" ? (
                              <div className="bg-red-200 h-8 w-24 mb-4 md:mb-0 rounded-md flex items-center justify-center">
                                <span className="text-xs text-red-500 font-normal">
                                  Pending
                                </span>
                              </div>
                            ) : (
                              <div className="bg-green-200 h-8 w-24 mb-4 md:mb-0 rounded-md flex items-center justify-center">
                                <span className="text-xs text-green-500 font-normal">
                                  Accepted
                                </span>
                              </div>
                            )}

                            {item.status === "pending" ? (
                              <div
                                className="bg-gray-200 h-8 w-24 mb-4 md:mb-0 rounded-md flex items-center justify-center"
                                onClick={() => handleDelete(item._id)}
                              >
                                <span className="text-xs text-red-500 font-normal cursor-pointer">
                                  Cancle Order
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          #{item._id.slice(18, 31)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {Object.keys(item.order).map((item) => (
                            <div>
                              <p className="flex flex-row ">
                                <span className="text-green-500">
                                  <FiBook />
                                </span>
                                {item}
                              </p>
                            </div>
                          ))}
                        </td>

                        <td className="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                          {Object.values(item.order).map((item) => (
                            <div>
                              <p className="flex flex-row ">
                                <span className="text-green-500"></span>
                                {item}
                              </p>
                            </div>
                          ))}
                        </td>
                        <td className="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                          <span className="tab-text">TK {item.grandTotal}</span>
                        </td>
                        <td className="text-sm text-green-500  font-semibold px-6 py-4 whitespace-nowrap ">
                          {item.payment}
                        </td>
                        <td className="text-sm text-gray-500  px-6 py-4 whitespace-nowrap ">
                          {item.createdAt.slice(0, 10)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrder;
