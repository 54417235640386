import React from "react";
import resume from "../../assets/resume.pdf";

import img1 from "../../assets/about/maliha-tabassum-about-photo.jpg";
import img2 from "../../assets/about/aboute.png";

const AboutUs = () => {
  return (
    <div className="xl:container xl:mx-auto lg:my-20 md:my-20 my-16 px-4">
      <div className="p-4 flex flex-shrink ">
        <div className="flex flex-shrink  sm:flex-row flex-col">
          <div className="flex flex-1">
            <img
              className="object-cover overflow-hidden  lg:hidden md:hidden rounded h-96"
              src={img1}
              alt="cover-img"
            />
            <img
              className="object-cover overflow-hidden hidden lg:block md:block mr-4 rounded w-[200px]"
              src="https://res.cloudinary.com/dhl7vd8co/image/upload/v1676632722/msvqs8biufprngdh2j6k.jpg"
              alt="cover-img"
            />
          </div>
          <div className="flex flex-1 flex-col justify-evenly py-2 lg:ml-4 ">
            <div className="flex rounded-md justify-center align-center">
              <div className="cursor-pointer  rounded mx-3">
                <a href="https://www.facebook.com/maliha.smita">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios-glyphs/30/000000/facebook-new.png"
                    alt="facebook"
                  />
                </a>
              </div>
              <div className="cursor-pointer  rounded mx-3">
                <a href="https://www.instagram.com/maliha_tabassum24/">
                  <img
                    src="https://img.icons8.com/ios-glyphs/30/000000/instagram-new.png"
                    alt="instagram"
                  />
                </a>
              </div>
              <div className="cursor-pointer  rounded mx-3">
                <a href="https://twitter.com/MalihaT81502430?s=9">
                  <img
                    src="https://img.icons8.com/ios-glyphs/30/000000/twitter-circled--v1.png"
                    alt="twitter"
                  />
                </a>
              </div>

              <button className="hover:bg-gray-400 text-gray-800 hover:text-hover-100 font-semi-bold rounded inline-flex items-center mx-3">
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <a href={resume} download>
                  Resume
                </a>
              </button>
            </div>
            <h2 className="object-cover text-gray-800 font-extrabold text-2xl ">
              Welcome To The Multifaceted Territory Of Dr. Maliha Tabassum
            </h2>
            <p className="text-gray-600 text-lg">
            Let's get introduced to a Bangladeshi novelist,singer and artist Maliha Tabassum who is also keen to set her footprint in direction based on her own plot. Maliha was born on 19th November, Dhanmandi, Dhaka. Apart from her creative career,she is a doctor and completed MBBS from Shaheed Suhrawardy Medical College. Now she is pursuing higher degree in psychiatry.
            </p>
            <p className="text-gray-600 text-lg hidden lg:block md:block">
              Maliha has developed inclination towards Bengali and English
              literature and music since her early days. Her first published
              piece of writing was from Jugantar and Prothom Alo newspaper back
              in 2012-14. She gained recognition as best classical singer and
              reciter in 2015 from Nazrul Academy sponsored by Tamuddin Majlish.
              She also achieved prize as radio personality from Radio Bhumi.
            </p>
          </div>
        </div>
      </div>

      <p className="py-3 mx-2 text-lg text-gray-600">
        <span className="lg:hidden md:hidden">
          Maliha has developed inclination towards Bengali and English
          literature and music since her early days. Her first published piece
          of writing was from Jugantar and Prothom Alo newspaper back in
          2012-14. She gained recognition as best classical singer and reciter
          in 2015 from Nazrul Academy sponsored by Tamuddin Majlish. She also
          achieved prize as radio personality from Radio Bhumi..
        </span>
        <br />
        <br />
        Maliha published her first novel, "Brittobondi" which was a Romantic Spy
        Thriller from Annesha Prokashan. Her debut novel turned a boon for her
        and she started to gain popularity among readers' community. Her
        published second novel, "Gigolo" was also a massive hit. It was based on
        male prostitution,first ever a complete fiction about male escort in
        Bangla.This book secured its place in top 30 novels published in 2020 by
        Banglamati Website's "Ekushey Shongkolon".
        <br />
        <br />
      </p>
      <div className="flex justify-between">
        <p className="text-lg mx-2 text-gray-600 lg:w-2/3">
          Maliha Tabassum's biggest break was her third novel, "Inferiority
          Complex". This novel introduced first ever fictional "Medical
          Examiner" character in Bangla. It garnered overnight stardom for
          Maliha Tabassum.Her fourth novel and second medical thriller "Achilles
          Er Tendon" surpassed the success of "Inferiority Complex". Maliha has
          always longed for making a revolutionary change in Bangladeshi Book
          industry and media by taking initiatives to internationalise novel
          based movie adaption. Despite being a part of youth,she opts for
          playing a pivotal role to convert her own medical thriller fiction
          into celluloid. The bridge between literature and media of Bangladesh
          can help us secure a firm position of Bangladeshi content into global
          platform- This is her firm belief.
        </p>
        <img
          className="object-cover overflow-hidden  lg:block hidden  rounded lg:w-1/3"
          src="https://res.cloudinary.com/dhl7vd8co/image/upload/v1676632304/zdul4qv8okyhyhqyv6ix.jpg"
          alt="cover-img"
        />
      </div>
      <div className="flex flex-1">
        <img
          className="object-cover overflow-hidden  lg:hidden mg:hidden rounded h-96"
          src="https://res.cloudinary.com/dhl7vd8co/image/upload/v1676632304/zdul4qv8okyhyhqyv6ix.jpg"
          alt="cover-img"
        />
      </div>
    </div>
  );
};

export default AboutUs;
